import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.css";
import App from "./App.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "../store";
import { history } from "../helpers/history";
import MainContext from "./assets/store/MainContext";
ReactDOM.render(
  <Router history={history}>
    <GoogleOAuthProvider clientId="492415652685-1ppv8tde3m6l549d018vhpm89iih00t6.apps.googleusercontent.com">
      <Provider store={store}>
        <MainContext>
          <App />
        </MainContext>
      </Provider>
    </GoogleOAuthProvider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
